var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Advertiser"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Advertiser of the campaign.")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['AdvertiserId', { initialValue: _vm.initialValue, rules: [{ required: true,
                message: 'Advertiser is required.' },
                ]}]),expression:"['AdvertiserId', { initialValue: initialValue, rules: [{ required: true,\n                message: 'Advertiser is required.' },\n                ]}]"}],attrs:{"placeholder":"Please select an advertiser","showSearch":true,"filterOption":true,"optionFilterProp":"children"}},_vm._l((_vm.advertiser.list.filter(ele => ele.Status === 0 )),function(adv){return _c('a-select-option',{key:adv.Id,attrs:{"value":adv.Id}},[_vm._v(_vm._s(adv.Name)+" ")])}),1)],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Campaign's name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'name', {
                rules: [
                  { required: true, message: 'Name is required.' },
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]),expression:"[ 'name', {\n                rules: [\n                  { required: true, message: 'Name is required.' },\n                  { max: 64, message: 'Maximum 64 characters allowed.'}\n                  ]}]"}],attrs:{"type":"text","placeholder":"Campaign Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Clicks Daily Limit"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. \"day shaping\". Set to 0 for unlimited clicks.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['clicks', { initialValue:0}]),expression:"['clicks', { initialValue:0}]"}],attrs:{"type":"number","min":"0","placeholder":"Clicks Daily Limit"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Daily Clicks per IP"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Daily limit of clicks per IP address. Set to 0 for unlimited impressions per IP address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit', { initialValue:5}]),expression:"['limit', { initialValue:5}]"}],attrs:{"type":"number","min":"5","placeholder":"Daily Clicks limit per IP address"}})],2)],1),_c('a-divider',{staticClass:"mt-3"}),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper"},[_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit"}},[_vm._v("Create Campaign")]),_c('button',{staticClass:"btn btn-light px-5 ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'campaigns' })}}},[_vm._v("Cancel ")])])])],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Create Campaign")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Create new campaign")])])
}]

export { render, staticRenderFns }